import { atom, selector } from 'recoil';
import {cp_client} from '../helpers/api';


const LoggedInUserProfile = selector({
    key: 'LoggedInUserProfile',
    get: async ({get}) => {
        try {
            const response = await cp_client.get('profile/');
            return response.data;
        }
        catch (error) {
            return null;
        }
        
    }
}
)

const LoginState = atom({
    key: 'LoginState',
    default: selector({
        key: 'LoginState/default',
        get: async ({ get }) => {
            const response = await get(LoggedInUserProfile)
            if(response){
                return {"logged_in": true}
            }
            else{
                return {"logged_in": false}
            }
        }
    })
}
)

export { LoggedInUserProfile, LoginState };