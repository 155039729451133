const getAPIStatusErrorMessage = ({err, response, defaultErrorMessage}) => {
	let success;
	let errorMessage;

	if(err){
		success=false
		errorMessage = (err.response && err.response.data.detail) || defaultErrorMessage || "Error";
	}
	else{
		if(response.data && response.data.success == false){
			success = false
			errorMessage = response.data.message || defaultErrorMessage || "Error";
		}
		else{
			success = true
			errorMessage = null
		}
	}
	if(Array.isArray(errorMessage)){
		errorMessage = errorMessage[0]
	}
	if(errorMessage && errorMessage.msg){
		errorMessage = errorMessage.msg;
	}
	return {success:success, errorMessage:errorMessage}
}

export {getAPIStatusErrorMessage}