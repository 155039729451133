import {Dropdown, Menu, Tooltip, Button} from 'antd';
import {PlusOutlined} from '@ant-design/icons';


function AppActionManu({appActionDispacher}) {

  const onSelectItem = async ({ item, key, keyPath, selectedKeys, domEvent }) => {
    appActionDispacher.triggerAction(key)
    }
  
  const items = [
    { label: 'Create App Space', key: 'create-appspace' }, // remember to pass the key prop
    /*
    { label: 'Create App', key: 'create-app' }, // which is required
    
    {
      label: 'sub menu',
      key: 'submenu',
      children: [{ label: 'item 3', key: 'submenu-item-1' }],
    },
    */
  ];

  

  return (
    <>
    <Dropdown
      trigger={['click']}
      overlay={
      <Menu items={items} onClick={onSelectItem}/>
      }
      >
      <Tooltip title="Actions">
        <Button type="primary" shape="circle" icon={<PlusOutlined />} />
      </Tooltip>
    </Dropdown>
    </>
    );
}

export default AppActionManu;