import {Layout} from 'antd';

const { Footer } = Layout;

export default function Component() {

  return (
    <>
      <Footer className="App-footer">
        Copyright © 2022 Save9 Technologies Pvt. Ltd. All rights reserved. <a href="https://uniconnapps.com/privacy/" target="_blank">Privacy</a> | <a href="https://uniconnapps.com/terms/" target="_blank">Terms</a> | <a href="mailto:support@uniconnapps.com" target="_blank">Support</a>
      </Footer>
    </>
  );
}