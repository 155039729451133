import {forwardRef, useRef, useImperativeHandle, useState, useEffect} from 'react'

import { Button, Empty, Modal } from 'antd'

import {
  FieldTimeOutlined
} from '@ant-design/icons';

const NotYetModal = forwardRef(({title, emailSubject, emailContext}, ref) => {
	if(!emailSubject){
		emailSubject="Request for " + (title || "upcoming functionality")
	}
	if(!emailContext){
		emailContext={}
	}

	let emailBody = "Hi team,\nI need additional suport on the following.\nDetail: " + (title || emailSubject)

	if(Object.keys(emailContext).length){
		emailBody = emailBody+"\nContext:"
		for(let [key,value] of Object.entries(emailContext)){
			emailBody = emailBody+"\n"+key+": "+value
		}
	}

	const emailLink = `mailto:support@uniconnapps.com?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`

	const [isModalOpen, setIsModalOpen] = useState(false);

	const showModal = () => {
	  setIsModalOpen(true);
	};

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useImperativeHandle(ref, () => ({
    show: showModal
  }));

  return (
	 <>
	  <Modal title={title} visible={isModalOpen} footer={null} onCancel={handleCancel}>
       <Empty description={<>This functionality is not available yet.</>} style={{ fontSize: '30px'}} imageStyle={{height: "200px", fontSize: "150px"}} image={<FieldTimeOutlined/>}>
       	<a target="_blank" href={emailLink}><Button type="primary">Raise Request/Talk to Support</Button></a>
       </Empty>
       {/*
       <textarea>
				{emailBody}
				</textarea>
				*/
				}
    </Modal>
	 </>
   )
})


const NotYetButton = (props) => {
	const { modalTitle, emailSubject, emailContext, children } = props;
	const modelRef = useRef()
	return (
		<>
			<NotYetModal ref={modelRef} title={modalTitle} emailSubject={emailSubject} emailContext={emailContext}  />
			<Button {...props} onClick={()=>modelRef.current.show()}>
				{children}
			</Button>
		</>
		)

}


export {NotYetModal, NotYetButton}
