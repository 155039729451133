import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import {
  RecoilRoot,
} from 'recoil';

import './index.css';
import App from './App';
//import Welcome from './routes/welcome';
//import Login from './routes/login';
//import AppSpaces from './routes/appspaces';
//import UniApp from './routes/uniapp';
import reportWebVitals from './reportWebVitals';
import ConfigbeeProvider from './providers/configbee-provider';

const Login = React.lazy(()=>import('./routes/login'));
const AppSpaces = React.lazy(()=>import('./routes/appspaces'));
const AppSpace = React.lazy(()=>import('./routes/appspace'));
const UniApp = React.lazy(()=>import('./routes/uniapp'));
const TriggerAction = React.lazy(()=>import('./routes/invoke-action'));
const ActionType = React.lazy(()=>import('./routes/action-type'));

//require('dotenv').config()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <RecoilRoot>
    <ConfigbeeProvider>
    <BrowserRouter>
    <React.Suspense fallback={<div style={{width:"100%", textAlign:"center", fontSize:"20px", paddingTop: "40px", marginRight: "auto", marginLeft: "auto"}}><div class="page-loader"></div>Loading</div>}>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="" element=<Navigate to="/appspaces" replace={true} /> />
          <Route path="appspaces" element={<AppSpaces/>} />
          <Route path="appspaces/:appspaceId" element={<AppSpace/>} />
          <Route path="appspaces/:appspaceId/apps/:appId" element={<UniApp/>} />
          <Route path="appspaces/:appspaceId/apps/:appId/action-types/:actionTypeId" element={<ActionType/>} />
          <Route path="appspaces/:appspaceId/apps/:appId/actions/:actionTypeId" element={<TriggerAction/>} />
        </Route>
        <Route path="/login" element={<Login/>} />
      </Routes>
  </React.Suspense>
  </BrowserRouter>
  </ConfigbeeProvider>
  </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();