import {Drawer, Button, Form, Input, Select, notification} from 'antd';
import React, {useState} from "react";
import { useRecoilValue } from 'recoil';
import {DataPlanesData, AppSpacesData} from "../state/appSpaces";
import {cp_client, get_dp_client} from '../helpers/api';
import {publish} from '../helpers/events';
import { getAPIStatusErrorMessage } from '../state/utils';


const CreateAppSpaceDrawer = ({openState}) => {
  const [form] = Form.useForm();

	const [open, setOpen] = openState;

	const showDrawer = () => {
  	setOpen(true)
  }

  const closeDrawer = () => {
  	setOpen(false)
  }

	const onFinish = async (values) => {
    let success, errorMessage, response;
    try{
      response = await cp_client.post('app-spaces/', {
        "data_plane_service_id": values["data-plane"],
        "name": values["name"]
      });
      ({success, errorMessage} = getAPIStatusErrorMessage({response:response}))
    }
    catch(err){
      ({success, errorMessage} = getAPIStatusErrorMessage({err:err}))
    }
    if(success){
      notification['success']({message: 'AppSpace Created'})
      closeDrawer();
      publish("AppAction::Success", {appSpaceId: response.data.id});
    }
    else{
      notification['error']({message: errorMessage || 'Error Creating AppSpace'})
    }
	}

	const onFinishFailed = () => {

	}

	const dataPlanesData = useRecoilValue(DataPlanesData);


  return (
  	<>
    <Drawer title="Create AppSpace" placement="right" visible={open} onClose={closeDrawer}>
      <Form
        form={form}
        name="basic"
        layout="vertical"
    		initialValues={{
      		name: "My Space",
          "data-plane": dataPlanesData[0].id
    		}}
    		onFinish={onFinish}
    		onFinishFailed={onFinishFailed}
    		autoComplete="off"
       >
       <Form.Item
        label="Cloud Location"
        tooltip="Data Center or an Enterprise Private cloud"
        name="data-plane"
        rules={[
          {
            required: true,
            message: 'Please select a Location!'
          },
        ]}
        >
        <Select>
          {dataPlanesData.map((each, i) => {
            return <Select.Option value={each.id} key={each.id}>{each.name}</Select.Option>
          })
          }
        </Select>
      </Form.Item>
       <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input a AppSpace name!',
          },
        ]}
        >
        <Input />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          align: "center",
          width: "100%"
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
      </Form>
    </Drawer>
    </>
    )
}


const CreateAppDrawer = ({openState, actionArgsState}) => {
	const [open, setOpen] = openState;
  const [actionArgs, setActionArgsState] = actionArgsState;

	const showDrawer = () => {
  	setOpen(true)
  }

  const closeDrawer = () => {
  	setOpen(false)
  }

	const onFinish = async (values) => {
    //const appSpaceDetailData = (await cp_client.get(`app-spaces/${values["app-space"]}/`)).data;

    //const dp_client = await get_dp_client(appSpaceDetailData.data_plane_service_id)
    const dp_client = await get_dp_client(fixedSpaceData.data_plane_service_id)

    let success, errorMessage, response;

    try{
      response = await dp_client.post('apps/', {
        app_space_id: fixedSpaceData.id,
        name: values["name"]
      });
    ({success, errorMessage} = getAPIStatusErrorMessage({response:response}))
    }
    catch(err){
      ({success, errorMessage} = getAPIStatusErrorMessage({err:err}))
    }
    if(success){
      notification['success']({message: 'App Created'})
      closeDrawer();
      publish("AppAction::Success", {appId: response.data.id, appSpaceId: fixedSpaceData.id});
    }
    else{
      notification['error']({message: errorMessage || 'Error Creating App'})
    }

	}

	const onFinishFailed = () => {

	}

	//const appSpacesData = useRecoilValue(AppSpacesData({pageNo: 1}));

  const fixedSpaceData = actionArgs.spaceData || {};

  const formInitialValues = {
          name: "My App",
          "app-space": fixedSpaceData.id
        }

  return (
  	<>
    <Drawer title="Create App" placement="right" visible={open} onClose={closeDrawer}>
      <Form
        name="basic"
        layout="vertical"
    		initialValues={formInitialValues}
    		onFinish={onFinish}
    		onFinishFailed={onFinishFailed}
    		autoComplete="off"
       >
       <Form.Item
        label="AppSpace"
        name="app-space"
        >
        {fixedSpaceData &&
        <Select value={fixedSpaceData.id} disabled>
          <Select.Option value={fixedSpaceData.id}>{fixedSpaceData.name}</Select.Option>
        </Select>
        }
        {/*!fixedSpaceData &&
        <Select>
          {appSpacesData.map((each, i) => {
            return <Select.Option value={each.id}>{each.name}</Select.Option>
          })
          }
        </Select>
        */}
      </Form.Item>
       <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          align: "center",
          width: "100%"
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
      </Form>
    </Drawer>
    </>
    )
}

export default ({appActionDispacher}) => {
	const actions = {
		"create-appspace": {
			componentClass: CreateAppSpaceDrawer,
			openState: useState(false),
      actionArgsState: useState({})
		},
		"create-app": {
			componentClass: CreateAppDrawer,
			openState: useState(false),
      actionArgsState: useState({})
		}
	}

	const triggerAction = (action_key, action_args) => {
			const [open, setOpen] = actions[action_key].openState;
      const [actionArgs, setActionArgs] = actions[action_key].actionArgsState;
      setActionArgs(action_args || {});
			setOpen(true);
	}

	appActionDispacher.triggerAction = triggerAction;

	const rederComponents = [];
	
	for(let each_key in actions){
		let Comp = actions[each_key].componentClass
		rederComponents.push(<Comp key={each_key} openState={actions[each_key].openState} actionArgsState={actions[each_key].actionArgsState}/>);
	}

	return (
	<>
	{rederComponents}
	</>
	)

}