import axios from 'axios';

const cp_client = axios.create({
     baseURL: process.env.REACT_APP_UCA_API_URL + "api/v0/",
     withCredentials: true
})

const dp_clients = new Map();

const do_dp_login = async (dp_client, dp_id) => {
     const cpLoginRes = await cp_client.post("/data-plane-login/",{data_plane_service_id: dp_id});
     
     const loginRes = await dp_client.post("/login/",{
          "access_token_id": cpLoginRes.data.access_token_id,
          "one_time_secret": cpLoginRes.data.one_time_secret
          })
}

const get_dp_client = async (dp_id) => {
     if(dp_clients.has(dp_id)){
          return dp_clients.get(dp_id)
     }
     const res = await cp_client.get(`/data-plane-services/${dp_id}/`);
     const api_endpoint = res.data.api_endpoint;

     const client = axios.create({
          baseURL: api_endpoint + "api/v0/",
          withCredentials: true
     })
     let loginInfo = null;
     try{
          loginInfo = await client.get("login-info/");
     }
     catch{
          await do_dp_login(client, dp_id);
     }
     dp_clients.set(dp_id, client);
     return client;
}


export {cp_client, get_dp_client};