import { atom, selector, selectorFamily } from 'recoil';
import {cp_client, get_dp_client} from '../helpers/api';


//const dummyAppsData = [{"id":"1", "name": "AQ - wellness 2.0"},{"id":"2", "name": "AQ - wellness 3.0"},{"id":"1", "name": "AQ - wellness 3.0"}]

const AppSpacesData = selectorFamily({
    key: 'AppSpaces',
    get: ({pageNo}) => async ({get}) => {
        if(pageNo == null){
            return null
        }
        
        const data = [];
        
        const response = await cp_client.get(`app-spaces/?page=${pageNo}`);
        for (let each of response.data.items){
          let db_client = await get_dp_client(each.data_plane_service_id);
          let apps_res = await db_client.get(`/apps/?app_space_id=${each.id}`);
          data.push({
            id: each.id,
            name: each.name,
            data_plane_service_id: each.data_plane_service_id,
            apps: apps_res.data.items
          })
        }
        return data;
    }
}
)

const UniAppsData = selectorFamily({
    key: 'UniApps',
    get: ({pageNo, spaceId}) => async ({get}) => {
        if(pageNo == null){
            return null
        }
        const appSpaceDetailData = await get(AppSpaceDetailData({spaceId: spaceId}));

        const dp_client = await get_dp_client(appSpaceDetailData.data_plane_service_id);

        const response = await dp_client.get(`apps/?app_space_id=${spaceId}&page=${pageNo}`);
        return response.data.items;
    }
}
)

const DataPlanesData = selector({
    key: 'DataPlanes',
    get: async ({get}) => {
        try {
            const response = await cp_client.get('data-plane-services/');
            return response.data.items;
        }
        catch (error) {
            return null;
        }
    }
}
)

const DataPlaneDetailData = selectorFamily({
    key: 'DataPlaneDetail',
    get: (dpId) => async ({get}) => {
        const response = await cp_client.get(`data-plane-services/${dpId}/`);
        return response.data;
    }
}
)

const AppSpaceDetailData = selectorFamily({
    key: 'AppSpaceDetail',
    get: ({spaceId}) => async ({get}) => {
        const response = await cp_client.get(`app-spaces/${spaceId}/`);
        return response.data;
    }
}
)

const AppDetailData = selectorFamily({
    key: 'AppDetail',
    get: ({appSpaceId, appId}) => async ({get}) => {
        const appSpaceDetailData = await get(AppSpaceDetailData({spaceId: appSpaceId}));

        const dp_client = await get_dp_client(appSpaceDetailData.data_plane_service_id);
        const response = await dp_client.get(`/apps/${appId}/`);
        return {...response.data,appSpaceDetailData: appSpaceDetailData};
    }
}
)

const AppClientsData = selectorFamily({
    key: 'AppClientsData',
    get: ({appSpaceId, appId, pageNo}) => async ({get}) => {
        const appSpaceDetailData = await get(AppSpaceDetailData({spaceId: appSpaceId}));

        const dp_client = await get_dp_client(appSpaceDetailData.data_plane_service_id);
        const response = await dp_client.get(`/app-clients/`, { params: { app_space_id: appSpaceId, app_id: appId, page_no: pageNo} });

        return response.data.items
    }
}
)

const AppClientDetailData = selectorFamily({
    key: 'AppClientDetailData',
    get: ({appSpaceId, clientId}) => async ({get}) => {
        if(!clientId)
            return null
        const appSpaceDetailData = await get(AppSpaceDetailData({spaceId: appSpaceId}));

        const dp_client = await get_dp_client(appSpaceDetailData.data_plane_service_id);
        const response = await dp_client.get(`/app-clients/${clientId}/`);

        return response.data
    }
}
)

const SpaceMembersData = selectorFamily({
    key: 'SpaceMembersData',
    get: ({spaceId, appId, userId, pageNo}) => async ({get}) => {
        if(pageNo == null){
            return null
        }

        //const appSpaceDetailData = await get(AppSpaceDetailData({spaceId: spaceId}));
        const params = { page: pageNo}

        if(userId){
            params["user_id"] = userId
        }

        const response = await cp_client.get(`/app-spaces/${spaceId}/members/`, { params: params});

        return response.data.items
    }
}
)

export {
    DataPlanesData, AppSpacesData, AppSpaceDetailData,
    AppDetailData, UniAppsData, DataPlaneDetailData,
    AppClientsData, AppClientDetailData, SpaceMembersData
    }
