import logo_h from '../logo-h.svg';
import { Layout, Row, Col, Typography, Button,
Dropdown, Card, Tag, Popover, List } from 'antd';
import { UserOutlined, GithubFilled } from '@ant-design/icons';

import { useRecoilValue } from 'recoil';
import {LoggedInUserProfile} from "../state/auth.js";
import {useAuthActions} from '../actions';

import {NotYetButton} from "./NotYet";

const { Header, Content } = Layout;

const { Title } = Typography;


export default function AppHeader() {

  const loggedInUserProfile = useRecoilValue(LoggedInUserProfile);
  if(!loggedInUserProfile){
    window.location = "/login";
  }
  console.log("loggedInUserProfile",loggedInUserProfile);


  const {logout} = useAuthActions();

  /*
  const profileOverlay = (
    <Card title="My Profile" style={{height: "unset", width: "unset", marginRight: "5px"}} className="General-card General-card-head-center" actions={[
      <Button onClick={doLogout}>Logout</Button>,
      <NotYetButton onClick={doLogout} modalTitle="Edit User Profile/Delete Account" emailContext={{"user_id": loggedInUserProfile.id}}>Edit Profile</NotYetButton>,
      ]}>
      <Card.Meta title="Email" description={loggedInUserProfile.email}/>
    </Card>
  );
  */

  const profilePopoverContent = (
    <div>
      <Card.Meta title="Email" description={loggedInUserProfile.email}/>
      <br/>
      <Button onClick={doLogout}>Logout</Button>
      &nbsp;
      <NotYetButton modalTitle="Edit User Profile/Delete Account" emailContext={{"user_id": loggedInUserProfile.id}}>Edit Profile</NotYetButton>
    </div>
  );

  const githubPopoverContent = (
    <div style={{textAlign: "center", fontWeight: "bolder", fontSize: "15px"}}>
      <a target="_blank" rel="noopener noreferrer" href="https://github.com/orgs/uniconnapps/discussions">Discussions</a><br/>
      <a target="_blank" rel="noopener noreferrer" href="https://github.com/uniconnapps/uca-examples-python">Python Examples</a><br/>
      <a target="_blank" rel="noopener noreferrer" href="https://github.com/uniconnapps/uca-starter-python">Python Starter</a><br/>
      <a target="_blank" rel="noopener noreferrer" href="https://github.com/orgs/uniconnapps/discussions/categories/q-a">Community Support</a><br/>
      <a target="_blank" rel="noopener noreferrer" href="https://github.com/uniconnapps">Organization Home</a><br/>
    </div>
  );

  async function doLogout(){
    await logout();
    window.location = "/login";
  }

  
  return (
    <>
    <Header className="App-header">
    <Row justify="space-between">
      <Col flex={16}>
        <Row>
          <Col>
            <img src={logo_h} className="App-logo" alt="Uniconnapps Logo" title="Uniconnapps"/>
          </Col>
          &nbsp;
          <Col>
          <Tag color="red">Beta</Tag>
          </Col>
        </Row>
      </Col>
      <Col flex={1} style={{marginTop: "auto", marginBottom: "auto"}}>
        <Row justify="end">
          {/*
          <Col>
            <Dropdown.Button overlay={profileOverlay} placement="bottom" icon={<UserOutlined />}>
            </Dropdown.Button>
            <Dropdown.Button overlay={profileOverlay} placement="bottom" icon={<UserOutlined />}>
            </Dropdown.Button>
          </Col>
          */}
          <Col>
            <Popover content={githubPopoverContent} title={"Community on GitHub"} placement="bottomRight">
              <Button style={{padding: "0px", width: "40px"}}><GithubFilled style={{fontSize: "22px"}}/></Button>
            </Popover>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Popover content={profilePopoverContent} title={"User Profile"} placement="bottomRight">
              <Button style={{padding: "0px", width: "40px"}}><UserOutlined style={{fontSize: "22px"}}/></Button>
            </Popover>
          </Col>
        </Row>
      </Col>
    </Row>
    </Header>
    </>
  );
}