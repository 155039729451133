import {cp_client} from '../helpers/api';


function useAuthActions() {
	async function request_otp(email, redirect_to_space_id){
		const response = await cp_client.post('login/', {email: email, redirect_to_space_id: redirect_to_space_id});
		return response;
	}
	async function login(email, otp) {
		const response = await cp_client.post('login/', {email: email, code: otp});
		return response;
	}

	async function logout() {
		const response = await cp_client.post('logout/');
		return response;
	}

	return {
        request_otp,
        login,
        logout
    }
}

export { useAuthActions };