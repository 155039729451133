import React, { useEffect } from 'react';
import { CbWrapper } from 'configbee-react';

const ConfigbeeProvider = ({ children }) => {
  return (
    <CbWrapper
      accountId="65f70d3a8d4fccd15246775d"
      projectId="65f70d3a8d4fccd15246775f"
      environmentId={process.env.REACT_APP_UCA_CB_ENV_ID}
    >
      {children}
    </CbWrapper>
  );
};

export default ConfigbeeProvider;