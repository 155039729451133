import './App.less';
import { Outlet, Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import {
  atom,
  useRecoilValue,
} from 'recoil';

import {Layout, Breadcrumb, Row, Col} from 'antd';

import AppHeader from "./components/AppHeader";
import AppActions from "./components/AppActions";
import AppActionManu from "./components/AppActionManu";
import AppFooter from "./components/AppFooter";

import momentTimezone from 'moment-timezone';

import WebFont from 'webfontloader';

import {useEffect} from 'react';

momentTimezone.tz.setDefault("UTC");



const { Content} = Layout;

const appBreadcrumbState = atom({
  key: 'appBreadcrumbState', // unique ID (with respect to other atoms/selectors)
  default: {"itemsData": []}, // default value (aka initial value)
});

const appActionDispacher = {
    triggerAction: null
  }

function  AppBreadcrumbItems(props) {
  const appBreadcrumbData = useRecoilValue(appBreadcrumbState);

  const itemsData = appBreadcrumbData.itemsData;
  return (
    <Breadcrumb>
    {
      itemsData.map((item, index) => (
      <Breadcrumb.Item key={index}><Link to={item.linkTo}>{item.name}</Link></Breadcrumb.Item>
    ))
    }
    </Breadcrumb>
    );
}

function App(props) {


  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Manrope:medium',]
      }
    });
   }, []);


  return (
    <div className="App">
      <Helmet>
        <title>Uniconnapps</title>
      </Helmet>
      <AppHeader />
      <AppActions appActionDispacher={appActionDispacher}/>
      <Layout className="App-body">
        <Col>
          <Row justify="space-between" align="middle">
          <Col>
            <AppBreadcrumbItems/>
          </Col>
          <Col>
          {/*<AppActionManu appActionDispacher={appActionDispacher}/>*/}
          </Col>
          </Row>
        </Col>
        <Col flex="2 0 2">
          <Content>
            <Outlet/>
          </Content>
        </Col>
      </Layout>
      <AppFooter />
    </div>
  );
}

export {appBreadcrumbState, appActionDispacher};
export default App;
